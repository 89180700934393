.email-sent{
    position: fixed;
    @apply w-10/12 md:w-1/2 xl:w-fit text-center py-3 md:py-4 xl:px-12 rounded-xl font-semibold flex items-center justify-center;
    bottom:40px;
    left:50%;
    transform: translateX(-50%);
    background-color: #65a39f;
    @apply bg-white border border-gray-900/10;
   
    svg{
        font-size: 1.5em;
    }
    p{
        @apply ml-2;
    }
}
.email-sent--show{
    opacity: 1;
    z-index: 101;
    transform: translate(-50%,0%);
    transition: .5s all;
}
.email-sent--hide{
    z-index: -1;
    opacity: 0;
    transform: translate(-50%,50%);
    transition: .9s all;
}