.newsletter-block {
    background: #222b40;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    @apply flex p-4 md:p-16 rounded-xl flex-wrap hidden;
    overflow: hidden;
    .ellipse-abs-2{
        @apply absolute hidden xl:block;
        right: 0px;
        bottom:0px;
        transform: rotate(180deg);

    }
    .ellipse-abs-1{
        @apply absolute;
        top:0px;
        left:0px;
       
    }
    // top:50%;
    .newsletter-block-text {
        @apply w-full lg:w-1/2 mx-auto;

        .title {
            p {
                @apply mx-0;
            }
        }

        form {
            @apply w-full relative mt-10 md:mt-12;

            input {
                @apply border border-emerald-500 w-full px-3 md:px-4 py-3 md:py-5 rounded-md;
            }

            button {
                @apply mt-2 md:mt-0 text-sm md:text-base lg:absolute w-fit bg-emerald-600 text-white font-medium py-3 px-4 md:px-6 mr-2 rounded-md;
                top: 50%;
                right: 0px;
                transform: translate(0%, -50%);
                transition: .3s ease;
                background-color: #65a39f;
                @apply bg-yellow-400 text-black;
                i {
                    @apply text-white ml-2 text-sm text-black;
          
                }

                &:hover {
                    // @apply  bg-yellow-500; 
                
                    transform: translate(0%, -50%) scale(1.05);
                }
            }
        }
    }

    .newsletter-block-img {
        @apply w-full lg:w-1/3 hidden;

        img {
            @apply absolute w-1/2 sm:w-2/5 lg:w-1/2 xl:w-fit;
            right: 0px;
            top: -50px;
        }
    }
}

@media screen and(max-width:1024px){
 
    .newsletter-block{
        
        .newsletter-block-text{
            form{
                button{
                    transform: translate(0%, 0%);
                }
            }
        }
    }
}
@media screen and(max-width:500px){
    .newsletter-block{
        .ellipse-abs-1{
            position: absolute;
            left: 120px;
            bottom:-50px;
            transform: rotate(270deg);
        }
    }
}