
.home {
   
    opacity: 0;
}

.content--hide {
    opacity: 0;
}

.content--show {
    transition: 1.2s ease;
    opacity: 1;
}