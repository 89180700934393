.footer{
    @apply  relative;
    overflow: hidden;
    background: #222b40;
    .ellipse-abs-2{
        @apply absolute;
        left: -200px;
        top:0px;
        transform: rotate(90deg);

    }
    .ellipse-abs-1{
        @apply absolute;
        right:-180px;
        bottom:0px;
        transform: rotate(270deg);
       
    }
    p{
        @apply text-gray-300 font-medium;
    }
    .footer-blocks{
        @apply text-white py-12 lg:py-24 flex flex-wrap justify-between;
       
        .footer-block{
            @apply w-full lg:w-30 2xl:w-1/5;
            p{
                @apply text-gray-50;
                font-size: 15px;
            }
            .footer-block-title{
                @apply flex items-center text-white font-semibold mb-6;
                @apply text-lg md:text-2xl;
                img{
                    width: 50px;
                    @apply mr-2;
                }

            }
        
            ul{
                font-size: 15px;
                .nav-foo{
                    margin-bottom:15px!important;
                    &:hover{
                        @apply underline;
                    }
                }
                li{
                    @apply flex mb-5 text-white;
                    svg{
                        @apply mr-2;
                        width: 20px;
                        height: 20px;
                        fill:white;
                    }
                    i{
                     
                    }
                    a,p{
                        @apply text-gray-50;
                        transition: .3s ease;
                        &:hover{
                            @apply text-white underline;
                        }
                    }
                    &:last-of-type{
                        @apply mb-5 md:mb-0;
                        
                    }
                }
            }
           
        }
        .footer-about{
            @apply mb-8 2xl:mb-0 lg:w-full 2xl:w-1/3;
            p{
                @apply leading-8;
            }
        }
    }
    .copy{
        @apply text-center border-t border-white/10 py-6;
        p{
            @apply font-medium ;
        }
    }
}