.descriptions {
    @apply py-16 md:py-24 lg:py-32;

    .wrapper {
        .description{
            &:first-of-type{
                @apply mb-12 md:mb-32;
            }
        }
    }
}