

.card{
    @apply w-full md:w-45 lg:w-2/5 mb-12 md:mb-20 lg:mb-0;
    &:last-of-type{
        @apply mb-0 md:ml-10;
    }
    .card-ico-block{
        @apply relative rounded-xl text-lg md:text-4xl flex items-center justify-center mx-auto ;
        width: 80px;
        height: 80px;
        .card-ico{
            @apply text-blue-800 text-emerald-800 text-white flex items-center justify-center bg-white bg-yellow-400 rounded-xl ;
            background-color: #65a39f;
            width: 80px;
             height: 80px;
            z-index: 2;
            transition: .3s ease;
            backface-visibility: hidden;
            -webkit-font-smoothing: subpixel-antialiased;
            .card-ico-svg{
                fill:#fff;
                width: 40px;
                height: 40px;
            }
        }
        .card-border{
            width: 80px;
            height: 80px;
            position: absolute;
            left: 6px;
            top: 6px;
            @apply rounded-xl border border-white/25 border-emerald-700;
            z-index: 1;
            transition: .2s ease;
        }

    }   
    .card-ico-block--over{
        // transform: scale(1.05);
    
        .card-ico{
            transform: scale(1.05) translateZ(0);
            z-index: 2;
        }
        .card-border{
            left:10px;
            top:10px;
            @apply bg-emerald-700/10 border border-transparent shadow-2xl ;
            z-index: 1;
        }
    }
    .card-ico-block--out{
        
    }
    h4,p{
        @apply text-center;
    }
    h4{
        @apply font-semibold text-2xl my-5 md:my-8;
    }
    p{
        @apply leading-8 mb-5 md:mb-8;
    }
    a{
        @apply  flex items-center justify-center w-fit mx-auto;
        transition: .3s ease;
        &:hover{

        }
        span{
            @apply underline;
        }
        i{
            @apply text-xs ml-2;
        }
    }
    
}

@media screen and(max-width:768px){
    .card{
        .card-ico-block{
            width: 60px;
            height: 60px;
            .card-ico{
                width: 60px;
                height: 60px;
            }
            .card-border{
                width: 60px;
                height: 60px;
            }
        }
    }
}