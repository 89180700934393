.header {
    position: relative;
    background: linear-gradient(180deg, #F9F8F6 0%, rgba(249, 248, 246, 0) 100%);
    .imgbg{
        @apply absolute ;
        z-index: 1;
        top:0px;
    }
    .header-block {
        z-index: 2;
        @apply py-24 md:py-32 lg:py-44 lg:pt-52 3xl:py-44 relative;
    }
    .header-wrapper {
        @apply flex-col lg:flex-row  flex items-center justify-between;
    }
    .header-desc {
        @apply w-full  lg:w-1/2  xl:w-2/5 mb-16 lg:mb-0;
        transition: 1s ease;
        h1 {
            @apply font-semibold mb-5 leading-tight;
        }
        p {
            @apply mb-5 text-lg leading-relaxed;
        }
        form {
            @apply w-full relative;
            .form-group{
                @apply relative;
                input {
                    @apply border border-emerald-500 w-full px-3 md:px-4 py-3 md:py-5 rounded-md border-gray-900;
                }
                button {
                    @apply mt-2 lg:mt-0 text-sm md:text-base lg:absolute w-fit bg-emerald-600 text-white font-medium py-3 px-4 md:px-6 mr-2 rounded-md;
                    top: 50%;
                    right: 0px;
                    transform: translate(0%, -50%);
                    transition: .3s ease;
                    background-color: #65a39f;
                    @apply bg-yellow-400 text-black;
                    i {
                        @apply text-white ml-2 text-sm text-black;
              
                    }
                    &:hover {
                        // @apply  bg-yellow-500; 
                        transform: translate(0%, -50%)  scale(1.05);
                        // transform: translate(0%, -50%) scale(1.03);
                    }
                }
            }
            .err{
                @apply mt-1 text-xs md:text-sm text-red-500 font-medium lg:absolute; 
            }
          
        }
    }

    .header-image {
        @apply w-full lg:w-45 xl:w-1/2 2xl:w-55;
        transition: 1s ease;
        img {
            @apply ml-auto rounded-md;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
            box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        }
    }
}
.header-transitions-loadpage--add{
    .header-desc{
        position: relative;
        transform: translateY(60px);
        opacity: 0;
        transition: 1s all ease;
    }
    .header-image{
        position: relative;
        transform: translateX(-60px);
        opacity: 0;
        transition: 1s all ease;
    }
}
.header-transitions-loadpage--remove{
    .header-desc{
        transform: translateY(0);
        opacity: 1;
        transition: 1s all ease;
    }  
    .header-image{
        position: relative;
        transform: translateX(0px);
        opacity: 0;
        transition: 1s all ease;
    } 
}
@media screen and(max-width:1024px){
    .header{
        .header-desc{
            form{
                .form-group{
                    button{
                        transform: translate(0%, 0%);
                        &:hover{
                            transform: translate(0,0%) scale(1);
                        }
                    }
                }
            }
        }
    }
}