.contactBlock{
    @apply bg-blue-50 bg-emerald-50 w-full sm:items-center flex flex-col sm:flex-row p-4 md:p-8 rounded-xl mb-3 md:mb-8;
    background-color: rgba(101,163,159,0.2);
    transition: .3s ease;
    &:hover{
        @apply bg-blue-600 bg-emerald-600;
        background-color: #65a39f;
        .contactBlock-ico{
            @apply  border-white;
        }
        .contactBlock-info{
            @apply text-white;
            h4{
                @apply text-white;
            }
        }
    }
    .contactBlock-ico{
        @apply bg-blue-700 bg-emerald-700 text-white rounded-full flex items-center justify-center mr-4 border-2 border-transparent;
        background-color: #65a39f;
        width: 50px;
        height: 50px;
        transition: .5s ease;
        // align-self: flex-start;  
        svg{
            @apply text-lg md:text-2xl text-white;
            fill:#fff;
        }
        i{
            @apply text-white;
        }
    }  
    .contactBlock-info{
        transition: .3s ease;
        h4{
            @apply text-lg md:text-2xl font-semibold mb-0 md:mb-1;
            transition: .3s ease;
        }
        a{
            @apply text-sm sm:text-base underline;
        }
    }
}
@media screen and(max-width:768px){
    .contactBlock{
        .contactBlock-ico{
            width: 40px;
            height: 40px;
        }
    }
}