.solutions {
    // @apply bg-blue-600 py-16 md:py-24 lg:py-32 relative;
    @apply bg-gray-50 py-16 md:py-24 lg:py-32;

    // background-color: #5138ee;
    // background-color: #65a39f;
    // overflow: hidden;
    // z-index: 1;
    // background-color: #fff;
    .imgbg{
        @apply absolute;
        // top:-150px;
        top:0px;
        // transform: translate(-50%,-50%);
        opacity:.3;
        z-index: 1;
    }
    .wrapper {
        z-index: 2;
        .title{
            @apply text-center mx-auto w-full md:w-1/2 mb-12 md:mb-24;
        }
        .cards{
            @apply flex items-center justify-around lg:justify-center flex-wrap;
        }
    }
}