.light {
    p {
        @apply text-white bg-white/10;
    }
    h2 {
        @apply text-white;
    }
}

.dark {
    p{
        @apply bg-emerald-300/10 text-emerald-700;
        background-color: rgba(101,163,159,0.1);
        color:#65a39f;
    }
    h2{

    }
}   

.title {
    p {
        @apply text-base md:text-lg font-semibold mb-5 md:mb-8 w-fit mx-auto py-1 px-4 rounded-md;
    }

    h2 {
        @apply  font-semibold text-2xl md:text-4xl leading-tight;
    }
}