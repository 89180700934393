.why{
    // @apply bg-blue-50 bg-gray-50 py-24 lg:py-32 pt-40 lg:pt-48 mt-72;
    @apply bg-blue-600 py-16 md:py-24 lg:py-32 relative;
    // background-color: #65a39f;
    background-color: #65a39f;
    .wrapper{
        .title{
           @apply text-center;
        }
        .why-cards{
            @apply mt-10 md:mt-16 flex flex-wrap justify-between;
        }
    }
   
}
