.why-card{
    @apply w-full md:w-47 xl:w-31 flex flex-col lg:flex-row  bg-white p-6 lg:p-8 rounded-xl mb-5 md:mb-8 items-start;
    transition:.3s ease;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    &:hover{
        @apply shadow-2xl;
        // transform: scale(1.03) translate(0,-3%);
    }
    .why-card-ico{
        @apply w-full md:w-1/5 flex items-center mb-3 md:mb-5;
        i,svg{
            @apply text-3xl md:text-5xl  mr-2;
            color:#65a39f;
            fill:#65a39f;
        }
        h3{
            @apply text-lg font-medium   md:mt-0 block lg:hidden;
        }
    }
    .why-card-text{
        @apply w-full xl:w-4/5;
        h3{
            @apply text-xl font-semibold mb-3 mt-3 md:mt-0 hidden lg:block;
        }
        p{
            @apply leading-8 mt-0 lg:mt-0;
        }
    }
}

@media screen and(max-width:600px){
    .why-card{
        .why-card-text{
            p{
                font-size: 15px;
            }
        }
    }
}
