.nav-responsive.preload {
    animation-duration: 0s !important;
    -webkit-animation-duration: 0s !important;
    transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
}

nav {
    @apply w-full flex items-center justify-between;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 4;
    left: 50%;
    transform: translate(-50%, 0%);
 
    .nav-wrapper {
        // @apply w-11/12 3xl:w-3/5 mx-auto flex justify-between; 
        @apply px-12 mx-auto flex justify-between;
    }

    .nav-logo {
        @apply flex items-center;

        .nav-logo-image {
            transition: .3s ease;
            width: 55px;
        }

        .nav-logo-desc {
            @apply ml-1 font-bold text-2xl flex;

            .letter {
                @apply mr-0.5;
                transition: .3s ease;
                height: 16px;
            }
        }
    }

    .nav-links {
        @apply flex items-center;

        .ico {
            @apply text-xl;
        }

        .nav-link {
            @apply mr-1 px-4 py-2 rounded-xl font-medium ;
            font-size: 15px;
            transition: .3s ease;
            .line{
                width: 0%;
                height: 2px;
                margin-top: 2px;
                @apply rounded-full;
                background-color: #65a39f;
                
                transition: width .3s ease;
            }
            &:last-of-type {
                @apply mr-0;
            }
            &:hover{
                background-color: rgba(0,0,0,0.02);
                // background-color: rgba(255,255,255,0.1);
                .line{
                    // animation:leftToRight .3s forwards;
                   
                }
                &:last-of-type{
                    background-color: transparent;
                }
            }
        }
        .nav-link--active{
            background-color: rgba(0,0,0,0.02);
            // background-color: rgba(255,255,255,0.1);
        }
        .nav-langs{
            @apply p-0 ml-1;
            width: 45px;
            position: relative;
            .nav-langs-active{
                background: #fff;
                
                height: 45px;
                width: 45px;
                p{
                    position: absolute;
                    @apply flex items-center justify-center;
                    height: 45px;
                    width: 45px;
                 
                }
                @apply flex items-center justify-center rounded-full bg-gray-50 shadow-sm font-medium cursor-pointer;
                transition: .3s ease;
                &:hover{
                    @apply  shadow-md ;
                }
            }
            .nav-langs-inactive{
                position: absolute ;
            
                width: 45px;
                @apply text-center flex flex-col items-center justify-center mt-3 bg-gray-50 rounded-xl shadow-md;
                left:50%;
                transform: translate(-50%,0%);
                z-index: -1;
                transition: .3s all ;
                p{
                    transition: .2s ease;
                    @apply p-2;
                    width: 45px;
                    &:hover{
                        @apply  cursor-pointer bg-gray-200;
                        // background-color: #65a39f;

                    }
                    &:first-of-type{
                        @apply rounded-t-xl;
                    }
                    &:last-of-type{
                        @apply rounded-b-xl;
                    }
                }
            }
          
            .nav-langs-inactive--show{
                transform: translate(-50%,0);
                opacity: 1;
                z-index: 1;
                visibility: visible;
            }
            .nav-langs-inactive--hide{
                visibility: hidden;
                z-index: -1;
                opacity: 0;
                transform: translate(-50%,0%);
                transition: .3s ease;
            }
        }
    }

    .show {
        // @apply block;
    }

    .hide {
        @apply hidden;
    }
}
.nav-langs-overlay{
    position: absolute;
    top:0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 3;
}
.nav-langs-overlay--show{
    display: block;
}
.nav-langs-overlay--hide{
    display: none;
}
.nav-wrapper {
    @apply py-2 w-full;

}

.nav-bcg--hide {
    @apply bg-transparent;
    transition: .2s ease;
    z-index: 5;
}

.nav-bcg--show {
    @apply bg-white shadow-lg py-1;
    transition: .2s ease;
    // background-color: #65a39f;
    // z-index: 5;
    .nav-logo {
        .nav-logo-image {
            width: 50px;
        }

        .nav-logo-desc {

            .letter {


                height: 14px;
            }
        }
    }
    .nav-links{
        .nav-link{
            // @apply font-medium;
        }
    }
}

.setWhiteBackground {
    background-color: white !important;
    // background-color: #65a39f!important;
}
.nav-wrapper-responsive.nav-bcg--show{
    @apply py-0;
}
.nav-wrapper-responsive {
    width: 100vw;
    .nav-wrapper-responsive-header {
        @apply flex justify-between px-5 py-2;
        .nav-wrapper-responsive-header-icons{
            @apply flex items-center;
            .nav-langs{
                @apply flex mr-8 rounded-md  shadow-sm;
                p{
                    @apply font-normal text-sm  bg-gray-100 py-1.5 px-4 flex items-center;
                    background-color: rgba(101, 163, 159, 0.2);
                    &:first-of-type{
                        // @apply mr-3;
                        @apply rounded-l-md border-r border-white;
                    }
                    &:last-of-type{
                        @apply rounded-r-md;
                    }
                }
                .active{
                    @apply font-semibold;
                }
            }
        }
        .nav-logo {
            img {
                width: 45px;
            }

            .nav-logo-desc {
                img {
                    width: 15px;
                }
            }
        }
    }

    .nav-wrapper-responsive-body {
        // height: 100%;
        height: fit-content;
        @apply bg-white shadow-md;
        overflow: hidden;

        .nav-responsive-links {
            .nav-link {
                list-style: none;
                @apply mb-6;
                a{
                    @apply flex items-center;
                }
                
                svg{
                    @apply text-lg;
                }
                span {
                    @apply font-medium ml-2 w-full;
                }
               
            }
        }

    }
    .hiddenCloseNav{
        // opacity: 0;
        width: 100vw;
        height: 200px;
      
        height:100vh;
        @apply block bg-transparent;
        
    }
    .hideHiddenCloseNav{
        @apply hidden;
    }
    .nav-responsive--show {
        // @apply block;
        // @apply px-4 pt-10 pb-4;
        // animation:showNav .22s forwards;
        @apply px-6;
        max-height: 100vh;
        padding-top: 15px;
        padding-bottom: 15px;
        transition: max-height 0.25s ease-in, padding 0.25s ease-in;
        @apply border-t border-gray-900/10 pt-5;
     
    }

    .nav-responsive--hide {
        // @apply hidden;
        max-height: 0;
        @apply px-6;
        padding-top: 0px;
        padding-bottom: 0px;
        transition: max-height 0.15s ease-out, padding 0.25s ease-out;
        overflow: hidden;
        // @apply px-4 py-0;
        // animation:hideNav .6s forwards;
    }
}

.nav-responsive {
    // @apply p-4 rounded-b-xl;
    // background: linear-gradient(0deg, rgba(103, 163, 159, 0.5), rgba(103, 163, 159, 0.5)), #FFFFFF;
    // @apply bg-white;
    // overflow: hidden;
    // z-index: -1;
    // .nav-responsive-img-abs1{
    //     @apply absolute;
    //     top:0px;
    //     right: -200px;
    //     transform: rotate(180deg)
    // }
    // .nav-responsive-img-abs2{
    //     @apply absolute;
    //     bottom:0px;
    //     left:0px;
    // }
    // .nav-responsive-img-abs3{
    //     @apply absolute hidden;
    //     top:0px;
    //     right:0px;
    // }
    // .nav-responsive-header{
    //     @apply flex justify-between hidden;
    //    .nav-responsive-header-logo{
    //     @apply flex items-center;
    //         .nav-logo-image {
    //             width: 45px;
    //             @apply mr-1;
    //         }
    //         .nav-logo-desc{
    //             @apply flex;
    //             .letter{
    //                 margin-right: 2px;
    //                 height: 14px;
    //             }
    //         }
    //    }
    //    .nav-responsive-header-esc{
    //     z-index: 101;
    //         i{
    //             @apply text-2xl;

    //         }
    //     }
    // }
    // .nav-responsive-links{
    //     @apply mt-20;
    //     .nav-link{
    //         list-style: none;
    //         @apply mb-5 flex items-center  w-full font-medium;
    //         &:last-of-type{
    //             @apply mb-0;
    //         }
    //         a{
    //             @apply flex items-center;
    //         }
    //         i{
    //             @apply mr-2 flex items-center justify-center text-gray-800 rounded-full text-base;
    //             width: 33px;
    //             height: 33px;
    //             // background:#65a39f;
    //         }
    //         span{
    //             // @apply border-b;
    //         }

    //     }
    // }
}

// .nav-responsive--show{
//     // @apply  bg-white ;
//     height: fit-content;
//     // width: 100vw;
//     z-index: 4;
//     top:0vh;
//     // transform: translate(0,0%);
//     // transition: .6s ease;
//     animation: showNav .6s ease;
// }
// .nav-responsive--hide{
//     // @apply fixed bg-white/50;
//     height: fit-content;
//     // width: 100vw;
//     // transform: translate(0%,-20%);
//     // transition: .3s ease;
//     animation:hideNav .6s ease;
//     opacity: 0;
// }

@keyframes leftToRight{
    from{
        width: 0px;
    }
    to{
        width: 70%;
    }
}
@keyframes showNav {
    from {
        // transform: translate(0%,-100%);
        // opacity: 0;
        // z-index: -1;
        // max-height: 0px;
        height: 0px;
    }

    to {
        // transform: translate(0,0);
        // opacity: 1;
        // z-index: 101;
        // max-height: fit-content;
        height: fit-content;
    }
}

@keyframes hideNav {
    from {
        // transform: translate(0,0);
        // opacity: 1;
        // z-index: 101;
        // height: fit-content;
        height: fit-content;
    }

    to {
        // transform: translate(0%,-140%);
        // opacity: 0;
        // z-index: -1;
        // height: 0px;
        height: 0px;
    }
}

@media screen and(max-width:600px) {
    nav {
        .nav-wrapper {
            .nav-logo {
                @apply flex items-center;

                .nav-logo-image {
                    width: 45px;
                }

                .nav-logo-desc {
                    .letter {

                        height: 14px;
                    }
                }
            }
        }
    }

}