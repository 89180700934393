.screenshots{
    @apply py-16 md:py-24 3xl:py-32 relative;
    overflow-x: hidden;
    .title{
        @apply text-center;
    }
    .images-slider{
        @apply mt-10 md:mt-16;
        .carousel-container-imgs{
            overflow: visible;
        }
        .react-multi-carousel-item {
            @apply w-full p-0 md:p-8;
            filter: blur(3px);
            transition: .5s ease;
        }
        .react-multi-carousel-item--active {
            filter: blur(0px);
            transition: .5s ease;
        }
        .react-multiple-carousel__arrow{
            @apply rounded-xl shadow-xl flex items-center justify-center;
            // overflow: visible;
            width: 48px;
            height: 48px;
            background-color: #65a39f;
            background-color: rgba(101, 163, 159,0.6);
            // @apply bg-yellow-400;
            transition: .3s ease;
            &:hover{
                transform: scale(1.05);
                background-color: #65a39f;
            }
        }
        img{
            @apply w-full md:w-4/5  mx-auto rounded-md; 
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

        }
    }
}
@media screen and(max-width:768px){
    .screenshots{
        .images-slider{
            img{
              
                @apply shadow-xl;
            }
            .carousel-container-imgs{
                .react-multi-carousel-item {
                    @apply px-2;
                }
              
            }
            .react-multiple-carousel__arrow{
                display: none;
                width: 15px;
                height: 15px;
                
            }
            .react-multiple-carousel__arrow::before {
                font-size: 14px;
              
            }
        }
    }
}