.question-block{
    @apply bg-white rounded-xl p-8 md:p-8 lg:p-10 mr-4 relative;
    width: 400px!important;
    // width: 100%;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    .num{
        @apply absolute bg-yellow-400 rounded-tl-xl rounded-br-xl rounded-xl font-semibold text-xl flex items-center justify-center;
        width: 50px;
        height: 50px;
        top:-10px;
        left:-10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    }
    .question{
        @apply text-base md:text-lg font-semibold mb-3 md:mb-5 lg:mb-8;
    }
    .answer{
        @apply leading-7 md:leading-8;
    }
}
@media screen and(max-width:1024px){
    .question-block{
        // width:330px!important;
        // width: fit-content;
        width: 100%!important;
        .num{

            width:40px;
            height: 40px;
        }
    }
}