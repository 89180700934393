@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@300;400;500;600;700&display=swap'); */

* {
    margin: 0px;
    padding: 0px;
    color: #5b5675;
    color:#1e293b;
    /* scroll-behavior: smooth; */
    
}
body{
  overflow-x: hidden;

}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
}

p,
a,
li,
label,
input,button {
    font-family: 'Inter', sans-serif;
}

.wrapper {
    @apply w-4/5 md:w-3/4 3xl:w-3/5 mx-auto;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  h1{
    @apply text-4xl md:text-6xl text-gray-700;
  }
  h2{
    @apply text-gray-700;
  }

    .reveal{
    position: relative;
    transform: translateY(60px);
    opacity: 0;
    transition: 1s all ease;
  }
  .reveal.active{
    transform: translateY(0);
    opacity: 1;
    transition: 1s all ease;
  }

  .reveal-from-left{
    transform: translateX(-60px);
  }
  .reveal-from-right{
    /* transform: translateX(60px); */
  } 