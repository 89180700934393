.animation {
    position: relative;
    // overflow: hidden;
    height: 100vh;
    width: 100vw;
    // background: linear-gradient(180deg, #F9F8F6 0%, rgba(249, 248, 246, 0) 50%);
    // background: linear-gradient(0deg, rgba(103, 163, 159, 0.5), rgba(103, 163, 159, 0.5)), #FFFFFF;
    animation: hideLoader 1.3s forwards;
    animation-delay: .8s;
    background-image: url(../../assets/images/hero.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .logo {
        height: 100vh;
        width: 100vw;
        @apply flex flex-col;

        .logo-img {
            width: 75px;
            height: 75px;
            @apply absolute rounded-full bg-white;
            animation: showLetters 1.5s forwards;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            overflow: hidden;

            .logoPart1 {
                position: absolute;
                top: 44.5%;
                left: 44%;
                transform: translate(-50%, -50%);
                animation: toRightLogo .75s forwards;
            }

            .logoPart2 {
                position: absolute;
                right: -21.5%;
                top: 51%;
                transform: translate(-50%, -50%) rotate(0deg);
                animation: toLeftLogo .75s forwards;
            }
        }

        .logo-text {
            // height: 20;
            @apply flex absolute;
            overflow: hidden;
            top: 57%;
            left: 50%;
            transform: translate(-50%, -50%);
            animation: showLetters 4s forwards;

            .letter {
                margin-right: 5px;

                &:last-of-type {
                    margin-right: 0px;
                    ;
                }
            }


        }
    }

}
.overlay{
    position: absolute;
    height: 100vh;
    width: 100%;
    background: linear-gradient(0deg, rgba(103, 163, 159, 0.5), rgba(103, 163, 159, 1)), #FFFFFF;
}
@keyframes hideLoader{
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}
@keyframes rotateLogo {
    from {
        transform: rotate(180deg);
    }

    to {
        transform: rotate(0deg) translate(-50%, -50%);
    }
}

@keyframes toRightLogo {
    from {
        left: -20%;
        opacity: 0;
    }

    to {
        left: 44%;
        opacity: 1;
    }
}

@keyframes toLeftLogo {
    from {
        right: -80%;
        opacity: 0;
    }

    to {

        right: -21.5%;
        opacity: 1;
    }
}

@keyframes showLetters {
    from {
        opacity: 0;

    }

    to {

        opacity: 1;
    }
}