.description{
    @apply flex items-center justify-between;
    .description-img{
        @apply w-full lg:w-45 2xl:w-1/2 mb-8 lg:mb-0 rounded-md;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
    .description-block{
        @apply w-full lg:w-1/2 2xl:w-2/5;
        .title{
            p{
                @apply mx-0;
             }
            h2{
                @apply w-full 2xl:w-4/5;
            }
            

        }
        .description-block-text{
            @apply leading-8 my-8;
        }
        .description-block-list{
            @apply flex flex-col sm:flex-row sm:items-center flex-wrap;
            .description-block-list-item{
                @apply  mb-3 sm:mb-5 flex items-center ;
                
                &:first-of-type{
                    @apply mr-8;
                }
                i{
                    // align-self: flex-start;
                    // width: 25px;
                    @apply py-0.5 px-1;
                    // height: 25px;
                    @apply bg-emerald-600 text-sm text-white rounded-full flex items-center justify-center mr-2;
               
                }
                p{
                    @apply font-semibold text-sm sm:text-base;
                }
            }
        }
    }
}
.normal{
    @apply flex-col lg:flex-row;
   
}
.reverse{
    @apply flex-col lg:flex-row-reverse;
    
}
