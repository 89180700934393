.faq{
    @apply bg-blue-600 py-16 md:py-24 lg:py-32 3xl:py-40 flex;
    background-color: #65a39f;
    @apply flex flex-col xl:flex-row items-center justify-between;
    overflow: hidden;
    // .wrapper{
       
        .faq-desc{
            @apply w-4/5  xl:w-1/5 mb-10 xl:mb-0  mx-auto;
            .title{
                p{
                    @apply mx-0;
                }
            }
            .faq-desc-text{
                @apply leading-8 mt-8 text-white;
            }
        }
        .faq-text{
            
            // overflow-x:hidden;
            @apply w-full xl:w-3/5 flex flex-row;
            .faq-item{
                @apply flex;

            }
            .react-multi-carousel-item{
                // margin:8px 70px;
                margin-left: 20px;
                margin-right: 50px;
                padding: 30px 30px 50px 30px;
                -webkit-touch-callout: none; 
                -webkit-user-select: none; 
                 -khtml-user-select: none; 
                   -moz-user-select: none; 
                    -ms-user-select: none;
                        user-select: none; 
                                             
                //  display:flex;
                // justify-content: center;
            }
            // .react-multi-carousel-list{
            //     overflow-x: hidden;
            //     overflow-y: visible;
               
            // }
            .react-multi-carousel-track {
                @apply pb-4;
            }
            .react-multi-carousel-dot-list {
                // bottom: -30px;
                .react-multi-carousel-dot {
                    margin:0px 6px;
                    button{
                        background-color: transparent;
                        border-color: rgba(255,255,255,0.5);
                        border-width: 3px;
                        height: 13px;
                        width: 13px;
                    }
                }
                .react-multi-carousel-dot--active{
                    button{
                        background-color: transparent;
                        border-color: #fff;
                    }
                }
            }
        }
    // }
}
.slider-wrapper{
    @apply p-4;
    // width: 800px;
}
.carousel-container {
    width: 100%;
    
   }
   @media screen and(min-width:2560px){
    .faq{
        .faq-text{
            li{
                margin:0px;
            }
        }
    }
   }
   @media screen and(max-width:1919px){
    .faq{
        .faq-text{
            .react-multi-carousel-item{
                
                // margin:8px 70px;
            
                justify-content: center;
                margin-right: 90px;

                // padding: 20px 20px;
            }
        }
    }
   }
   @media screen and(max-width:1440px){
    .faq{
        .faq-text{
            .react-multi-carousel-item{
                
                // margin:8px 70px;
            
                justify-content: center;
                margin-left:1px;
                margin-right: 0px;
                padding: 20px 20px 40px 20px;
            }
        }
    }
   }
   @media screen and(max-width:1024px){
    .faq{
        .faq-text{
            .react-multi-carousel-item{
                
                // margin:8px 70px;
                display:flex;
                justify-content: center;
                margin-right: 0px;
                // padding: 20px 20px;
            }
        }
    }
   }
   @media screen and(max-width:768px){
    .faq{
        .faq-text{
            .react-multi-carousel-item{
                
                // margin:8px 70px;
                // display:flex;
                // justify-content: center;
                margin-right: 0px;
                // padding: 20px 20px;
            }
        }
    }
   }