.privacy-info{
    @apply py-24;
    .privacy-info-block{
        @apply mb-10;
        h2{
            @apply font-semibold text-3xl mb-5;
        }
        p{
            @apply leading-8 mb-4;
        }
    }
}